import React, { useState, useEffect } from "react";

import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { SvgIconTypeMap } from "@material-ui/core";
import axios from "axios";
import { useRecoilState } from "recoil";
import { settings, getHeaders } from "../../settings";
import ContentWrapper from "../../components/ContentWrapper";
import MainHeader from "../../components/MainHeader";
import MainTable from "../../components/MainTable";
import TitleHeader from "../../components/TitleHeader";
import DividerWrapper from "../../components/DividerWrapper";

import ExceptionAdd from "./ExceptionAdd";

import {
  confirmationDialogState,
  dialogState,
  loadingOverlayState,
  snackBarState,
  handleErrorState,
} from "../../GlobalAtoms";
import AddButton from "../../components/Buttons/AddButton";

const Exception: React.FC<{
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
}> = () => {
  // States
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any>([]);
  const [stores, setStores] = useState<any>([]);
  const [companies, setCompanies] = useState<any>([]);
  const [company, setCompany] = useState<any>({stores: []});

  // Recoil
  const [dialog, setDialogState] = useRecoilState(dialogState);
  const [confirmationDialog, setConfirmationDialogState] = useRecoilState(
    confirmationDialogState
  );
  // eslint-disable-next-line no-unused-vars
  const [handleError, setHandleError] = useRecoilState(handleErrorState);
  // eslint-disable-next-line no-unused-vars
  const [loadingOverlay, setLoadingOverlayState] =
    useRecoilState(loadingOverlayState);
  // eslint-disable-next-line no-unused-vars
  const [snackBar, setSnackBar] = useRecoilState(snackBarState);

  useEffect(() => {
    let url = `${settings().apiUrl}/api/customer/orders/exception`;
    let headers = getHeaders();

    axios
      .get(url, { headers })
      .then((response) => {
        setData([...response.data.data]);
        setLoading(false);
      })
      .catch((error) =>
        setHandleError({ error: true, response: error.response })
      );

  }, []);

  const openAddDialog = (id: any) => {
    let url = `${settings().apiUrl}/api/customer/orders/exception/${id}`;
    let headers = getHeaders();
    setLoadingOverlayState(true);

    axios
      .get(url, { headers })
      .then((response) => {
        setLoadingOverlayState(false);
        setDialogState({
          ...dialog,
          open: true,
          title: 'Finalizar Pedido Emergencial',
          content: <ExceptionAdd setData={setData} data={response.data.data} />,
          maxWidth: "xl",
          transition: "regular",
        })    
      })
      .catch((error) =>
        setHandleError({ error: true, response: error.response })
      );

  }

  const columns = [
    {
      label: 'N.º',
      name: "id",
    },
    {
      label: "Sigla",
      name: "store_initials",
    },
    {
      label: 'Dia de Pedido',
      name: "exception_request_date",
    },
    {
      label: 'Dia de Entrega',
      name: "exception_delivery_date",
    },
    {
      label: 'Total para Pedido',
      name: "exception_request_total",
    },
    {
      label: 'Por Custo Extra',
      name: "exception_extra_fee",
    },
    {
      label: ' ',
      name: "id",
      options: {
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (
          value: number,
          tableMeta: { rowIndex: number },
          // eslint-disable-next-line no-unused-vars
          updateValue: any
        ) => (
          <AddButton
            onClick={() => openAddDialog(value)}
          >
            {'Finalizar Pedido'}
          </AddButton>
        ),
      },
    },
  ];

  return (
    <>
      <ContentWrapper loading={loading}>
        <MainHeader
          title={<TitleHeader>{'Pedidos de Emergência'}</TitleHeader>}
          button={<></>}
        />
        <DividerWrapper />
        <MainTable data={data} columns={columns} />
      </ContentWrapper>
    </>
  );
};

export default Exception;
